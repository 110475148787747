<template>
  <div class="game-date-time">
    <span style="text-transform: capitalize;">{{ gameDateTime | toFormattedDate('d-MMM-yyyy - HH:mm') }}</span> HRS
  </div>
</template>

<script>
export default {
  name: 'CarouselGameDateTime',
  props: {
    gameDateTime: {
      type: String,
      default: '13-May-2022 - 21:00 HRS',
    },
  },
};
</script>

<style scoped lang="scss">
.game-date-time {
  border-radius: 24px;
  background-color: rgba(19, 40, 57, 0.85);
  font-family: Roboto-Regular, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: white;
  width: fit-content;
  padding: 0.3rem 1.5rem 0.25rem;
}
</style>
